var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',[_c('b-form',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Título","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Título","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"Título","state":errors.length > 0 ? false:null},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Link","label-for":"link"}},[_c('validation-provider',{attrs:{"name":"Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"link","placeholder":"Link","state":errors.length > 0 ? false:null},model:{value:(_vm.data.link),callback:function ($$v) {_vm.$set(_vm.data, "link", $$v)},expression:"data.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Categoria","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"Categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormSelect',{attrs:{"state":errors.length > 0 ? false:null,"options":[
                    { value: null, text: 'Selecione uma Categoria'},
                    'Crédito',
                    'Finanças',
                    'Gestão',
                    'Mercado',
                    'Vendas' ]},model:{value:(_vm.data.category),callback:function ($$v) {_vm.$set(_vm.data, "category", $$v)},expression:"data.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição da Imagem","label-for":"alt-image"}},[_c('validation-provider',{attrs:{"name":"Descrição da Imagem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Descrição da Imagem","state":errors.length > 0 ? false:null},model:{value:(_vm.data.alt_image),callback:function ($$v) {_vm.$set(_vm.data, "alt_image", $$v)},expression:"data.alt_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Capa","label-for":"image"}},[_c('validation-provider',{attrs:{"name":"Capa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"image","placeholder":"Arraste ou selecione um arquivo","drop-placeholder":"Arraste o arquivo aqui.","browse-text":"Procurar","state":errors.length > 0 ? false:null},model:{value:(_vm.data.image),callback:function ($$v) {_vm.$set(_vm.data, "image", $$v)},expression:"data.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{attrs:{"variant":"primary","disabled":!!invalid},on:{"click":_vm.create}},[_vm._v(" Lançar ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }