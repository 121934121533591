/* eslint-disable no-restricted-syntax */
import methods from '@/enums/methods';
import Nerfetari from '../base/nerfetari';

class BlogService extends Nerfetari {
    async registerBlogArticle(data) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }

        return this.fetch({
            url: '/blog-article',
            method: methods.POST,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export default new BlogService();
