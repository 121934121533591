<template>
  <b-container>
    <b-card>
      <b-form>
        <validation-observer
          #default="{invalid}"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Título"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Título"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="data.title"
                    placeholder="Título"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Link"
                label-for="link"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Link"
                  rules="required"
                >
                  <b-form-input
                    id="link"
                    v-model="data.link"
                    placeholder="Link"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Categoria"
                label-for="category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Categoria"
                  rules="required"
                >

                  <BFormSelect
                    v-model="data.category"
                    :state="errors.length > 0 ? false:null"
                    :options="[
                      { value: null, text: 'Selecione uma Categoria'},
                      'Crédito',
                      'Finanças',
                      'Gestão',
                      'Mercado',
                      'Vendas',
                    ]"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Descrição da Imagem"
                label-for="alt-image"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Descrição da Imagem"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.alt_image"
                    placeholder="Descrição da Imagem"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Capa"
                label-for="image"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Capa"
                  rules="required"
                >

                  <b-form-file
                    id="image"
                    v-model="data.image"
                    placeholder="Arraste ou selecione um arquivo"
                    drop-placeholder="Arraste o arquivo aqui."
                    browse-text="Procurar"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-button
                variant="primary"
                :disabled="!!invalid"
                @click="create"
              >
                Lançar
              </b-button>
            </b-col>

          </b-row>
        </validation-observer>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import {
    BCard, BForm,
    BButton,
    BContainer,
    BCol,
    BRow,
    BFormGroup,
    BFormFile,
    BFormSelect,
    BFormInput,
} from 'bootstrap-vue';

import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';

import BlogService from '@/service/ptah/blog';

import '@validations';

export default {
    components: {
        BButton,
        BFormFile,
        BContainer,
        BCard,
        BForm,
        BCol,
        BRow,
        BFormGroup,
        BFormSelect,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
    },

    data() {
        return {
            data: { category: null },
        };
    },

    methods: {
        async create() {
            const response = await this.confirmAnAction('Você deseja mesmo criar esse artigo?');

            if (!response) return;
            this.callLoading(true);
            const { status } = BlogService.registerBlogArticle(this.data);
            this.calloading(false);
            if (status === 201) {
                this.modalSuccess('Artigo registrado com sucesso');
                return;
            }

            this.modalError('Não foi possível cadastraro artigo');
        },
    },
};
</script>
